import { useConnect } from "@stacks/connect-react";
import { verifyMessageSignature } from "@stacks/encryption";
import React, { useState } from "react";
import { StacksMainnet } from "@stacks/network";

function Auth(props: {
  stxAddress?: string;
  onContinue: (data: {
    signature: string;
    publicKey: string;
    nonce: string;
  }) => Promise<void>;
}) {
  const { doAuth, sign } = useConnect();
  const [loading, setLoading] = useState(false);
  return (
    <div className="flex h-screen items-center justify-center">
      <div
        className="p-4 rounded-lg min-w-[370px] flex flex-col gap-3"
        style={{
          backgroundImage:
            "linear-gradient(152.97deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%)",
        }}
      >
        <h1 className="text-2xl text-white font-semibold">Wallet Auth</h1>
        {props.stxAddress && (
          <>
            <p className="text-white">
              You are logged in as <br />
              <strong>{props.stxAddress}</strong>
            </p>
            <button
              className="bg-white hover:bg-gray-200 text-black font-bold py-2 px-4 rounded"
              onClick={() => doAuth()}
            >
              Switch Account
            </button>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={async () => {
                try {
                  setLoading(true);
                  const { nonce } = await fetch("/api/issue", {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      address: props.stxAddress,
                    }),
                  }).then((res) => {
                    if (!res.ok) {
                      throw new Error(res.statusText);
                    }
                    return res.json();
                  });

                  await new Promise<void>((resolve, reject) => {
                    const message = `Sign in to Alex as ${props.stxAddress} nonce ${nonce}`;
                    sign({
                      network: new StacksMainnet(),
                      message: message,
                      onFinish: async (data) => {
                        const isValid = verifyMessageSignature({
                          message,
                          publicKey: data.publicKey,
                          signature: data.signature,
                        });
                        if (!isValid) {
                          reject(new Error("Invalid signature"));
                          return;
                        }
                        props
                          .onContinue({
                            ...data,
                            nonce,
                          })
                          .then(resolve)
                          .catch(reject);
                      },
                      onCancel: () => {
                        reject(null);
                      },
                    });
                  });
                } finally {
                  setLoading(false);
                }
              }}
              disabled={loading}
            >
              {loading ? "Loading..." : "Continue to Discourse"}
            </button>
          </>
        )}
        {!props.stxAddress && (
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => doAuth()}
          >
            Connect Wallet
          </button>
        )}
      </div>
    </div>
  );
}

export default Auth;
