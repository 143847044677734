import React, { useEffect, useState } from "react";
import { AppConfig, openSignatureRequestPopup } from "@stacks/connect";
import { Connect, UserSession } from "@stacks/connect-react";
import Auth from "./Auth";
import { verifyECDSA, hashMessage } from "@stacks/encryption";
import { StacksMainnet } from "@stacks/network";

const appConfig = new AppConfig(
  ["store_write", "store_read", "publish_data"],
  "https://auth.alexlab.co"
);

const userSession = new UserSession({ appConfig: appConfig });

const Root = () => {
  const [stxAddress, setStxAddress] = useState<string>();
  useEffect(() => {
    if (userSession.isUserSignedIn()) {
      setStxAddress(userSession.loadUserData().profile.stxAddress.mainnet);
    }
  }, []);
  return (
    <Connect
      authOptions={{
        userSession,
        appDetails: {
          name: "ALEX",
          icon: "https://cdn.alexlab.co/logos/ALEX_Token.png",
        },
        onFinish: (user) => {
          setStxAddress(user.authResponsePayload.profile.stxAddress.mainnet);
        },
      }}
    >
      <Auth
        stxAddress={stxAddress}
        onContinue={async (result: {
          signature: string;
          publicKey: string;
          nonce: string;
        }) => {
          // const data = userSession.loadUserData();
          const search = new URLSearchParams(window.location.search);
          const sso = search.get("sso");
          const sig = search.get("sig");
          const response = await fetch("/api/verify", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...result,
              sso,
              sig,
            }),
          }).then((res) => {
            if (!res.ok) {
              throw new Error(res.statusText);
            }
            return res.json();
          });
          window.location.href = response.redirectURL;
        }}
      />
    </Connect>
  );
};

export default Root;
